<template>
	<div id="home">
		<div class="frame">
			<div style="display: flex; justify-content: center;">
				<img style="width: 300px" src="../assets/construction.svg">
			</div>
			<div>
				<p style="text-transform: uppercase; text-align: center;">under construction</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HomeView'
}
</script>

<style scoped>
.frame{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
}
</style>
